import { Icon } from '@blueprintjs/core';
import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { BreadcrumbStoreContext } from './Layout';

export type BreadcrumbAlias = {
    name: string;
    alias: string;
};

export class BreadcrumbAliases {
    breadcrumbAliases: IObservableArray<BreadcrumbAlias> = observable.array<BreadcrumbAlias>([]);

    constructor() {
        makeAutoObservable(this);
    }

    pushAlias(id: string, alias: string) {
        const bc = this.breadcrumbAliases.find((it) => it.name == id);
        if (bc && bc.alias != alias) {
            this.breadcrumbAliases.remove(bc);
            this.breadcrumbAliases.push(bc);
        }
        if (!bc) {
            this.breadcrumbAliases.push({ name: id, alias: alias });
        }
    }

    clearAliases(path: string) {
        const namesInPath = path
            .split('?')[0]
            .split('/')
            .filter((value) => value.length > 0);
        this.breadcrumbAliases.replace(
            this.breadcrumbAliases.filter((it) => namesInPath.includes(it.name))
        );
        console.log();
    }
}

type Breadcrumb = {
    href: string;
    name: string;
};

export default function BreadcrumbView() {
    const bcs = useContext(BreadcrumbStoreContext);
    const router = useRouter();
    const paths = router.asPath
        .split('?')[0]
        .split('/')
        .filter((value) => value.length > 0 && value != 'auth0_callback');

    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(getBreadcrumbs());

    function getBreadcrumbs(): Breadcrumb[] {
        return [
            { href: '/', name: 'Home' },
            ...paths.map((path, ix) => {
                const href = '/' + paths.slice(0, ix + 1).join('/');
                const name = path;
                return { href, name };
            }),
        ];
    }

    useEffect(() => {
        setBreadcrumbs(getBreadcrumbs);
    }, [router]);

    if (paths.length == 0) {
        return <div></div>;
    }

    return (
        <div>
            <style jsx>
                {`
                    .breadcrumb-item:last-child .link {
                        font-weight: 600;
                        color: inherit;
                    }

                    .link:hover {
                        color: #215db0;
                        cursor: pointer;
                    }

                    .breadcrumb-item:last-child .link:hover {
                        cursor: default;
                        color: inherit;
                    }

                    .link,
                    .icon {
                        text-decoration: none;
                        color: #5f6b7c;
                    }
                `}
            </style>
            <ul
                className="breadcrumb-list"
                style={{
                    display: 'flex',
                    listStyleType: 'none',
                    padding: '20px 0 16px 0',
                    // height: '30px',
                }}>
                {breadcrumbs.map((breadcrumb, ix) => {
                    const name = bcs?.breadcrumbAliases.find(
                        (it) => it.name == breadcrumb.name
                    )?.alias;
                    return (
                        <li
                            className="breadcrumb-item"
                            key={ix}
                            style={{
                                float: 'left',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                fontSize: '16px',
                            }}>
                            <div className="link" onClick={() => router.push(breadcrumb.href)}>
                                {name
                                    ? name
                                    : breadcrumb.name.charAt(0).toUpperCase() +
                                      breadcrumb.name.slice(1)}
                            </div>
                            {ix != breadcrumbs.length - 1 && (
                                <div
                                    className="icon"
                                    style={{
                                        paddingInline: '5px',
                                    }}>
                                    <Icon icon="chevron-right" />
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
